const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");

firebase.initializeApp({
    apiKey: 'AIzaSyAoziqI_ndBvlJI7VQMWKhk3nzFEzd-C0A',
    authDomain: 'diary-fb.firebaseapp.com',
    projectId: 'diary-fb'
});

export let firestore = firebase.firestore();
